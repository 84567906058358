.wrapper {
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
}

.header {
  background-color: #047fff;

  width: 95vw;
  height: calc(65px + 2.5vh);
  max-height: 90px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  padding: 0 2.5vw;
}

.header-logo {
  height: calc(30px + 2.5vh);
  max-height: 60px;
}
