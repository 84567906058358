body {
  margin: 0;
  font-family: "Faculty Glyphic", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  color: #0011AD;
  background-color: #f0f1f1;
}

@media (prefers-color-scheme: dark) {
  body {
    color: #f0f1f1;
    background-color: #0011AD;
  }
}
